import React, {useState, useEffect} from 'react'
import axios from 'axios';
import qs from 'qs'
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import {Text} from '../../components/ui-kit/Typo'
import {useTranslation} from 'react-i18next';
import {useParams, useHistory} from "react-router-dom";
import Seo from "../../components/general/Seo";

export default function PackageDetails(props) {

    let {eventPackage, orderId, token, phone} = props;
    const {t} = useTranslation(['package', 'seo']);
    const packageTitle = eventPackage && eventPackage.pakage ? eventPackage.pakage.title : 'Default Package Title';

    const [btResponse, setBtResponse] = useState(undefined)
    const [btResponsePending, setBtResponsePending] = useState(false)
    const [billingInfo, setBillingInfo] = useState({email: '', address: '', city: '', county: ''});
    let {lang, placeLink, cpid} = useParams();
    const history = useHistory();

    const payPackage = () => {
        setBtResponsePending(true);
        let ePkg = {...eventPackage};
        ePkg.location = null;
        ePkg.events = [];
        ePkg.pakage = null;
        ePkg.place = null;
        ePkg.clientPackage.address = billingInfo.address;
        ePkg.clientPackage.city = billingInfo.city;
        ePkg.clientPackage.county = billingInfo.county;
        ePkg.clientPackage.email = billingInfo.email;
        axios.post(process.env.REACT_APP_BUDDY_SERVICE + '/place/default/package/pay', ePkg)
            .then(res => {
                    if (res.data)
                        window.top.location.href = res.data; //window.top.location.href
                    setBtResponsePending(false);
                },
                err => {
                    setBtResponsePending(false);
                    //setError({pakageNotFound: true});
                });
    }

    useEffect(() => {
        if (token && orderId) {
            let body = `orderId=${orderId}&token=${token}`
            console.log(body)
            axios.post(process.env.REACT_APP_BT_ENDPOINT + '/getFinishedPaymentInfo.do', qs.stringify({
                orderId: orderId,
                token: token
            }), {headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}})
                .then(res => {
                        if (res.data)
                            setBtResponse(res.data)
                    },
                    err => {
                        //setError({pakageNotFound: true});
                    });
        }
    }, [])

    const goToTerms = () => {
        history.push(`/${lang}/iframe/${eventPackage.place.link}/terms/${eventPackage.clientPackage.id}?t=${phone}`)
    }

    return (
        <Grid container spacing={3} justify="space-around" alignItems="center">
            <Seo title={`${t('general:nav.items.package')} ${packageTitle} - ${t('seo:seo_book.step11.title')}`}
                 description={t('seo:seo_book.step11.description')}/>

            <Grid item md={4}>
                <Text type="subtitle" uppercase>{t('details.title')}</Text>
            </Grid>

            <Grid item md={8} xs={12}>
                <Grid container spacing={3} justify="space-around" alignItems="center">

                    <Grid item xs={12}>
                        <Text type="subtitle2">{t('details.subtitle')}{eventPackage.clientPackage.id}</Text>
                        <br/>
                        <Stack sx={{width: '100%'}} spacing={2}>
                            {btResponse && btResponse.actionCode != 0 && <Alert variant="filled" severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {btResponse.actionDesc}
                            </Alert>}
                            {btResponse && btResponse.actionCode == 0 && <Alert variant="filled" severity="success">
                                <AlertTitle>Success</AlertTitle>
                                {btResponse.actionDesc}
                            </Alert>}
                        </Stack>
                        <br/>
                        <Grid container spacing={1}>
                            <Grid item xs={4}><Text type="body">{t('details.client_name')}</Text></Grid>
                            <Grid item xs={8}><Text type="body">{eventPackage.clientPackage.title}</Text></Grid>

                            {false && <Grid item xs={4}><Text type="body">{t('details.place_name')}</Text></Grid>}
                            {false && <Grid item xs={8}><Text type="body">{eventPackage.place.name}</Text></Grid>}

                            <Grid item xs={4}><Text type="body">{t('details.package_name')}</Text></Grid>
                            <Grid item xs={8}><Text type="body">{eventPackage.pakage.title} / {eventPackage.type}</Text></Grid>

                            <Grid item xs={4}><Text type="body">{t('details.price')}</Text></Grid>
                            <Grid item xs={8}><Text
                                type="body">{eventPackage.clientPackage.payAmount} {eventPackage.place.placeInfo.currency}</Text></Grid>

                            <Grid item xs={4}><Text type="body">{t('details.status')}</Text></Grid>
                            <Grid item xs={8}><Text
                                type="body">{eventPackage.status === 'CANCELED' ? t('details.canceled') : eventPackage.clientPackage.paid ? `${t('details.paid')}  / ${eventPackage.clientPackage.payMethod}` : t('details.booked')}</Text></Grid>

                            {eventPackage.location &&
                                <Grid item xs={4}><Text type="body">{t('details.location')}</Text></Grid>}
                            {eventPackage.location &&
                                <Grid item xs={8}><Text type="body">{eventPackage.location.name}</Text></Grid>}

                            <Grid item xs={4}><Text type="body">{t('details.terms')}</Text></Grid>
                            <Grid item xs={8}><Text
                                type="body">{eventPackage.clientPackage.terms ? t('details.terms_good') :
                                <a href={`https://sbdy.app/${eventPackage.place.placeInfo.placeId}/t/${eventPackage.clientPackage.id}`}>{t('details.terms_bad')}</a>}</Text></Grid>

                            <Grid item xs={4}><Text type="body">{t('details.staff_name')}</Text></Grid>
                            <Grid item xs={8}><Text
                                type="body">{eventPackage.staff.rank == 999 ? t('details.staff_pending') : eventPackage.staff.name}</Text></Grid>

                            <Grid item xs={4}><Text type="body">{t('details.planning')}</Text></Grid>
                            {eventPackage.events.length > 0 && <Grid item xs={8}>
                                {eventPackage.events.map((e, index) => {
                                    return (
                                        <Text
                                            type="body">{moment(e.startDate).format('DD.MM.yyyy')} | {moment(e.startDate).format('HH:mm')} - {moment(e.endDate).format('HH.mm')}</Text>
                                    )
                                })}</Grid>}
                            {eventPackage.events.length == 0 && <Grid item xs={8}>
                                <Text type="body">{t('details.events_pending')}</Text></Grid>}
                        </Grid>
                    </Grid>

                    {!eventPackage.clientPackage.terms &&
                        <Grid item xs={12} className={"text-center"}>
                            <Button variant="outlined" color="secondary" onClick={() => goToTerms()}>
                                {t('details.btn_terms')}
                            </Button>
                        </Grid>}

                    {eventPackage.place.placeInfo.onlinePayments && eventPackage.clientPackage.payDate == null && eventPackage.clientPackage.terms && eventPackage.clientPackage.payMethod == 'online' && eventPackage.status === 'BOOKED' &&
                        <Grid item xs={12} className={"text-center"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} className={"text-center"}>
                                    {eventPackage.clientPackage.needsBillingInfo &&
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            type="text"
                                            autocomplete="email"
                                            label={t('details.email')}
                                            onChange={(event, newValue) => {
                                                var newVal = event.target.value;
                                                setBillingInfo({...billingInfo, email: newVal})
                                                console.log(billingInfo, newVal)
                                            }}
                                            value={billingInfo.email}
                                        />}
                                    {eventPackage.clientPackage.needsBillingInfo &&
                                        <TextField
                                            required
                                            fullWidth
                                            id="address"
                                            type="text"
                                            autocomplete="address"
                                            label={t('details.address')}
                                            onChange={(event, newValue) => {
                                                var newVal = event.target.value;
                                                setBillingInfo({...billingInfo, address: newVal})
                                            }}
                                            value={billingInfo.address}
                                        />}
                                    {eventPackage.clientPackage.needsBillingInfo &&
                                        <TextField
                                            required
                                            fullWidth
                                            id="city"
                                            type="text"
                                            autocomplete="city"
                                            label={t('details.city')}
                                            onChange={(event, newValue) => {
                                                var newVal = event.target.value;
                                                setBillingInfo({...billingInfo, city: newVal})
                                            }}
                                            value={billingInfo.city}
                                        />}
                                    {eventPackage.clientPackage.needsBillingInfo &&
                                        <TextField
                                            required
                                            fullWidth
                                            id="county"
                                            type="text"
                                            autocomplete="county"
                                            label={t('details.county')}
                                            onChange={(event, newValue) => {
                                                var newVal = event.target.value;
                                                setBillingInfo({...billingInfo, county: newVal})
                                            }}
                                            value={billingInfo.county}
                                        />}
                                </Grid>
                                <Grid item xs={12} className={"text-center"}>
                                    <Button variant="outlined" color="secondary" onClick={() => payPackage()}
                                            disabled={(eventPackage.clientPackage.needsBillingInfo && (billingInfo.email.length < 5 || billingInfo.address.length < 5 || billingInfo.city.length < 2 || billingInfo.county.length < 2)) || btResponsePending}>
                                        {t('details.btn_pay')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>}

                </Grid>
            </Grid>
        </Grid>
    )
}
